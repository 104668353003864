import { Button } from 'modules/v2/common/AtomicDesign/atoms';
import { useState } from 'react';
import { cn } from 'modules/v2/common/utils';
import { SmartPhoneIcon, SendEmailIcon } from 'modules/v2/common/components/SvgIcon';
import { NEW_PASSWORD_METHOD } from './utils';

function SetMethodPassword({ defineMethod }) {
  const [isActive, setIsActive] = useState(NEW_PASSWORD_METHOD.EMAIL);
  return (
    <div className="w-full flex flex-col items-center justify-center">
      <h2 className="font-extrabold text-2xl mb-3.5">Confirm your account</h2>
      <span className="text-neutral-500 font-normal mb-[30px]">
        You haven’t setup a password yet. Choose an option to get a verification code.
      </span>

      {/* <div
        className={cn(
          'w-full h-[68px] flex items-center p-3.5 rounded-md border border-neutral-200 bg-neutral-00 mb-3.5 cursor-pointer',
          {
            'border-2 border-primary-500 bg-primary-50': isActive === NEW_PASSWORD_METHOD.SMS,
          },
        )}
        onClick={() => setIsActive(NEW_PASSWORD_METHOD.SMS)}
      >
        <SmartPhoneIcon />
        <div className="ml-3 text-neutral-400">
          <p className="font-bold text-neutral-800">Text message (SMS)</p>
        </div>
      </div> */}

      <div
        className={cn(
          'w-full h-[68px] flex items-center p-3.5 rounded-md border border-neutral-200 bg-neutral-00 cursor-pointer',
          {
            'border-2 border-primary-500 bg-primary-50': isActive === NEW_PASSWORD_METHOD.EMAIL,
          },
        )}
        onClick={() => setIsActive(NEW_PASSWORD_METHOD.EMAIL)}
      >
        <SendEmailIcon />
        <div className="ml-3 text-neutral-400">
          <p className="font-bold text-neutral-800">Send email</p>
        </div>
      </div>

      <Button size="lg" full className="mt-[30px]" onClick={() => defineMethod(isActive)}>
        Send code
      </Button>
    </div>
  );
}

export default SetMethodPassword;
